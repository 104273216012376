import React, { useState, useEffect } from "react"
import { Parallax } from "react-parallax"

export default function ParallaxSection({ src, altTag, height }) {
  const [width, setWidth] = useState(0)

  useEffect(() => {
    if (typeof window === `undefined`) return

    setWidth(window.innerWidth)
  }, [typeof window !== `undefined` ? window.innerWidth : 0])

  if (!src) return null

  return (
    <Parallax
      // blur={1}
      bgImage={src}
      bgImageAlt={altTag}
      strength={0}
      disabled={width <= 350}
    >
      <div
        style={{ height: width > 1500 ? 500 : width <= 400 ? 100 : height }}
      />
    </Parallax>
  )
}

ParallaxSection.defaultProps = {
  altTag: "",
  height: 350,
  src: "",
}
